<template>
	<div
		ref="navigatorPanel"
		class="panel announces theme-light mt-xs-only-0 mt-sm-only-0 mt-md-only-0 pt-4"
	>
		<b-container :tag="tag">
			<div class="row mb-3">
				<b-col cols="12" lg="4">
					<navigator-filters
						:stickyKitProps="stickyKitProps"
						:filterName="filterName"
					/>
				</b-col>

				<b-col cols="12" :lg="groupData.groups.length > 0 ? 8 : 12">
					<navigator-content
						:cardComponent="cardComponent"
					/>
				</b-col>
			</div>
		</b-container>
	</div>
</template>

<script>
import NavigatorContent from '~/components/navigator-v2/body/NavigatorContent.vue';
import NavigatorFilters from '~/components/navigator-v2/body/NavigatorFilters.vue';

export default {
	name: 'NavigatorBody',
	props: {
		cardComponent: {
			type: String,
			default: null,
		},
		stickyKitProps: {
			type: Object,
			default: null,
		},
		filterName: {
			type: String,
			default: null,
		}
	},
	components: {
		NavigatorContent,
		NavigatorFilters,
	},
	inject: ['groupData'],
	watch: {
		'$route.query.page': {
			handler() {
				this.$scrollTo(this.$refs.navigatorPanel);
			},
		},
	},
};
</script>

<style scoped>

</style>
