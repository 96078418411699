<template>
	<div>
		<div
			ref="sticky-element"
			class="navigator-filters d-block d-lg-none"
		>
			<div
				class="collapse-btn d-flex"
				@click="toggleFiltersList"
			>
				<div>
					{{ filterName ? filterName : 'Дополнительные параметры' }}
				</div>
				<div
					class="dropdown-toggle d-flex align-items-center"
					:class="isOpen && 'rotate-arrow'"
				/>
			</div>
			<b-collapse
				id="collapse-1"
				v-model="isOpen"
			>
				<div
					id="events-filters-modal"
					class="filters-modal d-block mb-4"
				>
					<ul class="nav flex-column" role="tablist">
						<li
							v-for="item in groupData.groups"
							:key="('li-' + item.id)"
							class="nav-item"
						>
							<filters-accordion
								:group-of-options="item"
								:which-filter-is-open="whichFilterIsOpen"
								@setOpenFilter="setOpenFilterHandler"
							/>
						</li>
					</ul>
				</div>
			</b-collapse>
		</div>

		<div
			id="events-filters-modal"
			class="filters-modal d-none d-lg-block"
		>
			<ul class="nav flex-column" role="tablist">
				<li
					v-for="item in groupData.groups"
					:key="('li-' + item.id)"
					class="nav-item"
					v-show="item?.options && item.options.length > 0"
				>
					<filters-accordion :group-of-options="item" />
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import FiltersAccordion from '~/components/navigator-v2/body/filters/FiltersAccordion.vue';
import _without from 'lodash/without';
import stickyMixin from '~/mixins/stickyMixin';

export default {
	name: 'NavigatorFilters',
	components: { FiltersAccordion },
	mixins: [stickyMixin],
	props: {
		stickyKitProps: {
			type: Object,
			default: null,
		},
		filterName: {
			type: String,
			default: null,
		}
	},
	inject: ['groupData', 'setFilter'],
	provide() {
		const optionsData = {};

		Object.defineProperty(optionsData, 'selectedOptions', {
			enumerable: true,
			get: () => this.selectedOptions,
		});

		return {
			optionsData,
			setSelectedOptions: (arr) => {
				arr.forEach((el) => {
					if (!this.selectedOptions.includes(+el)) {
						this.selectedOptions = [...this.selectedOptions, el];
					}
				});
			},
			deleteFromSelectedOptions: (val, id) => {
				let ids = [];
				if (id === 6 && val.length > 1) {
					ids = this.groupData.groups[1].options.map((el) => el.id);
				}
				if (id === 6 && val.length === 1) {
					ids = this.groupData.groups[1].options
						.filter((el) => el.parent_id === val[0])
						.map((el) => el.id);
				}
				const filtered = _without(this.selectedOptions, ...val, ...ids);
				this.selectedOptions = filtered;
			},
		};
	},
	data() {
		return {
			selectedOptions: [],
			isOpen: false,
			whichFilterIsOpen: 0,
			stickyKit: {
				element: 'sticky-element',
				container: 'sticky-container',
				offset_top: 113,
				offset_top_mobile: 113,
				to: 1260,
			},
		};
	},
	watch: {
		selectedOptions: {
			handler(newSelectedOptions) {
				this.setFilter('taxonomyOptions', newSelectedOptions);
			},
			deep: true,
		},
	},
	created() {
		if (this.stickyKitProps) {
			this.stickyKit = this.stickyKitProps;
		}
	},
	methods: {
		toggleFiltersList() {
			this.isOpen = !this.isOpen;
			this.whichFilterIsOpen = 0;
		},
		setOpenFilterHandler(id) {
			if (this.whichFilterIsOpen !== id) {
				this.whichFilterIsOpen = id;
			} else {
				this.whichFilterIsOpen = 0;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "assets/styles/sk_variables";
@import "assets/styles/bootstrap/scss/functions";
@import "assets/styles/bootstrap/scss/variables";
@import "assets/styles/bootstrap/scss/mixins";

@include media-breakpoint-down(md) {
	.filters-modal {
		position: relative;
		width: 100%;
		z-index: 1001;
		background: $theme-light;
	}
	.filters-modal .custom-switch {
		padding-top: 0.75rem;
	}

	.filters-footer {
		position: relative;
		bottom: 0;
		width: 100%;
		background: $white;
		padding: 0.75rem;
		margin: 0 0 1rem 0;
	}
	.filters-footer .buttons-group {
		justify-content: flex-start !important;
		height: auto !important;
	}
	.filters-footer .buttons-group .btn-link {
		order: 2;
	}
	.filters-footer .buttons-group .btn-primary {
		order: 1;
	}
}

.collapse-btn {
	padding: 1.2rem;
	background: $white;
	cursor: pointer;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $theme-light;
}

.rotate-arrow {
	transform: rotate(180deg);
}

.navigator-filters {
	position: relative;
	z-index: 100;
}
.nav {
	background: #FFFFFF;
	border: 0px solid #F0F0F5;

	.nav-item {
		border: none;
		padding: 0;
	}
}
</style>
